/* body{
    overflow-y: hidden;
} */
.roles-main {
    background-color: white;
}

.roles-list {
    background-color: #1a2530;
    overflow-y: auto;
    height: 90vh;
}


.roles-panel-title {
    background-color: #1a2530;
    padding: 1.5vh;
}

.roles-permissions-title {
    padding: 1vh;
    border-bottom: solid 0.5px #B9BDBD;
}

.roles-list-row.selected {
    background-color: teal;
}

.roles-list-col {
    color: white;
    border-bottom: solid #263545 0.02px;
    padding: 2%;
    cursor: pointer;
    width: 100%;
}

.roles-list-col:hover {
    background-color: teal;
}

.roles-permissions {
    height: 90vh;

}

.roles-permissions-tree {
    height: 86vh;
    overflow-y: auto;
}

.roles-permissions-footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: solid 0.5px #B9BDBD;

    padding: 1vh;
}