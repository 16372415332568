@import '~antd/dist/antd.css';

body {
  height: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2d4050;

}

.back-fff {
  background-color: white !important;
}

.tr-table:hover {
  background-color: rgb(175, 194, 219) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Estilos para la pista del scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Estilos para el scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f1f1f1;
}

/* Estilos para el pulgar del scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

/* Estilos para el pulgar del scrollbar al pasar el mouse por encima */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
