.card-room {
  height: 8vh;
  border: solid 0.5px;
  border-color: gray;
  background-color: #f0f2f5;
}

.card-room-sel {
    height: 8vh;
    border: solid 0.5px;
    border-color: gray;
    background-color: rgb(216, 216, 216);
  }

.card-room:hover {
  cursor: pointer;
  background-color: rgb(216, 216, 216);
}
