.image-container {
    position: relative;
    display: flex;
    justify-content: center;

}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
}

.overlay:hover {
    opacity: 1;
}

.custom-image {
    align-self: center;
    max-height: 20vh;
    min-height: 20vh;
}