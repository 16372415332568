.view-bridges{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.bridges{
    background-color: white;
    width: 250px;
    height: calc(100vh - 30px);
    overflow-y: auto;
    position: fixed;
}


.card{
    padding: 10px 15px;
    border-radius: 1px;
    width: 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    margin: 5px !important;
}

.cardPuentes{
    background-color: #1a2530 !important;
}

.card h3{
    color: white;
    font-size: 17px;
    cursor: pointer;
}

.card label{
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.content-bridges-home{
    width: calc(100% - 250px);
    display: flex;
    height: calc(100vh - 30px);
    margin-left: 250px;
    justify-content: center;
    align-items: center;
}

.content-bridges-title{
    text-align: center;
    font-size: 40px;
}

.content-bridges{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 250px;;
}

.card-active{
    background-color: white;
}

.card-active label, .card-active h3{
    color: #1a2530;
}

.inspections-bridge{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.inspections-bridge article{
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
}

.inspection-bridge{
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 1px;
    width: 320px;
    min-width: 320px;
    height: 130px;
    border-radius: 3px;
}

.inspection-bridge label{
    color: white;
    font-size: 14px;
}

.Enprogreso{
    background-color: #109325;
}

.Terminado{
    background-color: #1a2530;
}

.Cancelado{
    background-color: #932610;
}

.contentPoint{
    width: 100%;
    background-color: white;
    height: calc(100vh - 36px);
}

.viewPDF{
    width: calc(100% - 260px);
    margin-left: 250px;
}

.closePDF{
    position: fixed;
    padding: 10px 15px;
    font-size: 13px;
    cursor: pointer;
    color: white;
    background-color: tomato;
    border: none;
    border-radius: 5px;
    margin: 5px;
}