.puntosDRSCPS{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

}

.puntosDRSCPS li{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.puntosDRSCPS li.color0{
    background-color: #2C0730;
    color:white;
}

.puntosDRSCPS li.color1{
    color: #2C0730;
    background-color:white;
}

.puntosDRSCPS li .table{
    border-top:solid 1px #2C0730;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: solid 1px #2C0730;
    padding: 1px;
}

.puntosDRSCPS .table .row-table{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 6px;
}

.puntosDRSCPS .table .row-table .col-table{
    width: 50%;
    text-align: left;
}

.col-table-titulo{
    width: 90%;
}

.foto{
    width: 70%;
    height: 100%;
    font-size: 10px;
    background-color: #303CDE;
    color:#fff;
    border: 0px;
}

.puntosDRSCPS .caption{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.puntosDRSCPS{
    font-size: 12px;
}

.puntosDRSCPS h1{
    font-size: 14px;
    text-align: left;
    width: 85%;
}

.status{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
}