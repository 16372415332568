.infoWindowStyles {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.list-view {
    display: flex;
    justify-content: space-between;
}

.attribute-view {
    height: 50vh;
    overflow-y: auto;
    width: 48%;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex-direction: column;
}

.attribute-title {
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 1rem;
}

.attribute-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5rem;
}

.attribute-label {
    font-weight: bold;
    margin-right: 0.5rem;
}

.attribute-value {
    margin-left: 0.5rem;
}

.event-list {
    padding: 1rem;
}

.event-list-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.event-list-items {
    list-style: none;
    padding: 0;
}

.event-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.event-id,
.event-name,
.event-date {
    margin-right: 1rem;
}

.event-id {
    font-weight: bold;
}

.event-name {
    flex: 1;
}

.event-date {
    color: gray;
}