.filtrosBusqueda{
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
}

.filtrosBusqueda article{
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    padding: 20px 0px;
    margin-left: 10px;
}


.filtrosBusqueda label{
    font-size: 14px;
    color: black;
}

.filtrosBusqueda select,.filtrosBusqueda input{
    height: 30px;
    border-radius: 3px;
    color: #605E5D;
    border:solid 1px #E1DEDB;
    font-family: 'Lato';
    margin-top: 3px;
    margin-bottom: 10px;
    width: 175px;
}

.guardar{
    background-color: green;
    padding: 10px 25px;
    border-radius: 5px;
    border-bottom: solid 2px #21AF0D;
}

.content{
    width: 100%;
}

.content table{
    width: 100%;
    margin-bottom: 50px;
}

.content table th{
    width: 9%;
    background: #25064C;
    color:white;
}

.content table th.ini{
    width: 8%;
    background: #CC0550;
    color:white;
}

.content table td{
    padding: 10px 0px;
}

.content table .info{
    background-color: white;
}

.content table .info:hover{
    color: white;
    background-color: #25064C;
}

.semaforo{
    position: fixed;
    z-index: 12;
    bottom: 5px;
    right: 5px;
    background-color: #000;
    width: 300px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.semaforo h1{
    color:white;
    font-size: 13px;
    width: 85%;
    text-align: left;
    margin-left: 5px;
}

.semaforo article{
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.red{
    background-color: red;
}

.orange{
    background-color: orange;
}

.green{
    background-color: green;
}

.deductivas{
    width: 300px;
    height: 400px;
    /*background-color: rgb(228, 222, 222);*/
    position: fixed;
    z-index: 11;
    padding: 4px;
    bottom: 50px;
    right: 5px;
    /*border:solid 1px  rgb(197, 189, 189);*/
    background-color: #fff;
    border: solid 1px #F8F6F5; 
	-webkit-box-shadow: 9px 9px 16px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 9px 9px 16px 0px rgba(0,0,0,0.75);
	box-shadow: 9px 9px 16px 0px rgba(0,0,0,0.75);
	border-radius: 5px;
}

.cabeceraDeductivas{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.deductivasInfo{
    display: flex;
    flex-direction: column;
}

.deductivasInfo article{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.deductivasInfo input,.deductivasInfo textarea{
    width: 100%;
    font-family: 'Lato';
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
}

.deductivasInfo input{
    height: 25px;
}

.deductivasInfo textarea{
    height: 150px;
}

.titulo{
    width: 90%;
}

.guardar_ .guardar{
    color:white;
}