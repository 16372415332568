.sectionSearch{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #1a2530;
    width: 100%;
}

.sectionSearch article{
    display:flex;
    flex-direction: column;
    margin:20;
}

.sectionSearch label{
    color:#FFF;
    text-align: center;
}

.sectionSearch input, .sectionSearch select, .formInput{
    width: 200px;
    height: 25px;
    border-radius: 5px;
    margin: 10px;
    background: #2d4050;
    border: none;
    color: #FFF;
}

.botonBusqueda{
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    background-color: #2d4050;
    border: none;
}

.newTopicButton, .new{
    border: none;
    background-color: #2d4050;
    color: #FFF;
    border-radius: 5px;
    padding: 5px;
    margin: 10px 10px;
    cursor: pointer;
}

.new {
    background-color: #1a2530;
    font-size: 40px;
    font-weight: bold;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: fixed;
    right: 10px;
    bottom: 10px;
}

.newTopicButton:hover, .new:hover{
    background-color: #2d4070;
}

.modalTopic, .modalRegistry{
    background-color: rgba(0,0,0,.7);
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.formTopic, .formRegistry{
    width: 50%;
    height: 75%;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px;
    margin-left: -100px;
}

.modalRegistryUpload{
    background-color: rgba(0,0,0,.7);
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.formRegistryUpload{
    margin-left: -100px;
    margin-top: -200px;
    width: 50%;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px;
}

.formRegistryUpload div button{
    cursor: pointer;
    border: none;
    background-color: #2d4070;
    color: #FFF;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
}

.headerRegistry{
    width: 50%;
    /*height: 40px;*/
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    justify-content: flex-end;
    align-items: flex-end;
}

.footer, .footerUpload{
    margin-left: -100px;
    width: 50%;
    /*height: 40px;*/
    background-color: #FFF;
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    justify-content: flex-end;
    align-items: flex-end;
}

.footer button, .footerUpload button{
    color: #FFF;
    border: none;
    background-color: #1a2530;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.footer button.cancelar, .footerUpload button.cancelar{
    background-color: #FFF;
    color: #1a2530;
    border: solid 0.5px #1a2530;
}

.headerRegistry label{
    color: #FFF;
    background-color: #F00;
    font-weight: bold;
    font-size: 20px;
    width: 30px;
    text-align: center;
    cursor: pointer;
}

.formRegistry input, .formRegistry select{
    border: solid 1px #1a2530;
    height: 30px;
    background-color: #FFF;
    text-align: justify;
    padding-left: 10px;
    border-radius: 5px;
    margin-bottom: 15px; 
}

.users_edit{
    text-align: right;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.edit-img, .view-img{
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #1a2530;
}

.view-img{
    margin-right: 10px;
}

.logbook{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.card_logbook{
    background-color: #FFF;
    padding: 0px 0px 20px 0px;
    border-radius: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.topic_card{
    background-color: #FFF;
    color: #FFF;
    padding: 5px;
}

.status_card, .duration_card{
    padding: 0px 15px;
}

.users_card{
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
}


.tableReleases{
   margin: 10px;
}

.noDatabita{
    margin-top: 100px !important;
}

.loader-cente,
.loader-center{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loader-cente{margin-top: 5px;}
.loader-center{ margin-top: 150px; }

.loader {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: rgb(201, 73, 14) rgb(201, 73, 14) transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #0099ff #0099ff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 52px;
    height: 52px;
    border-color: rgb(201, 73, 14) rgb(201, 73, 14) transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  #cssTable td{
    text-align: center !important; 
    vertical-align: middle !important;
}