.bitacora{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.bread{
  background-color: #1a2530;
}

.breadOption{
  /*background-color: #2d4050;*/
  background-color: transparent;
  border: none;
  color: #FFF;
  padding: 3px 10px 3px 10px;
  margin: 5px;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
}

.breadOption:hover{
  border-radius: 5px;
  background-color: #2d4050;
}

.bitacoraOnline{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*background-color: #1a2530;*/
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;
}

.view{
  cursor: pointer;
  margin-left:  10px;
  margin-top: 5px;
  color: white;
  border: none;
  background-color: #1a2530;
  padding: 10px;
  border-radius: 5px;
}

.bitacoraOnline2{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  width: 100%;
}

.folder{
  border-radius: 5px;
  border: solid 1px #FFF;
  margin: 10px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
  background-color: transparent;
}

.folder label{
  cursor: pointer;
}

.folder-img {
  width: 30px;
  height: 30px;
  color: #FFF;
  margin-right: 20px;
}

.folder2{
  display: flex;
  flex-direction: row;
  border: none;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  text-decoration: none;
  color: #FFF;
  cursor: pointer;
  background-color: transparent;
}

.folder2 label{
  cursor: pointer;
}

.folder-img2 {
  width: 10px;
  height: 10px;
  color: #FFF;
  margin-right: 5px;
}



.options-img{
  width: 20px;
  height: 15px;
  color: #FFF;
}

.menu_derecho{
  background-color: #1a2530;
  width: auto;
  position: absolute;
  padding: 15px 15px;
  border-radius: 5px;
  margin-top: 30px;
  cursor: default;
  z-index: 100;
}

.buttonOptions{
  background-color: transparent;
  border:none;
  cursor: pointer;
  width: 30px;
}

.menu_derecho li{
  margin-bottom: 10px;
  color: black;
}

.menu_derecho button{
  width: 100%;
  cursor: pointer;
  padding: 5px;
  font-size: 15px;
  border: none;
  background-color: transparent;
  text-align: left;
  color: #FFF;
}

.menu_derecho button:hover{
  color: #2d4050;
}

.botonesAdd{
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.botonesAdd button{
  background-color: #1a2530;
  border: none;
  color: #2d4050;
  font-size: 20px;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.botonesAdd button:hover{
  color: #1a2530;
  background-color: #2d4050;
  border: solid 1px #1a2530;
}

.newFolderName{
  width: 100%;
  height: 100vh;
  margin-top: -25px;
  position: fixed;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.newFolderName_{
  background: #FFF;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 30px;
  border-radius: 10px;
}



.newFolderName_ label{
  color: #000;
  font-size: 20px;
}

.newFolderName_ input[type="text"]{
  border: solid 1px #CCC;
  border-radius: 2px;
}

.newFolderName_ section{
  padding: 10px 10px 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.newFolderName_ section button{
  background-color: #1a2530;
  border: none;
  padding: 10px;
  color: #FFF;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}

.newFolderName_ section button.cancel{
  background-color: transparent;
  color: #1a2530;
}

.fileManager{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
}

.leftMenu{
  border-left: solid 0.5px #2d4050;
  background-color: #1a2530;
  /*width: 250px;*/
  width: auto;
  max-width: 400px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: auto; 
}

.tree{
  background: transparent;
  /*background-color: red;*/
  color: #FFF;
  border: none;
  padding: 5px;
  font-size: 14px;
  width: auto;
  /*width: 95%;*/
}

.tree label{
  cursor: pointer;
  padding: 2px 10px;
}

.treeLeaf{
  display: none;
}

.viewButtons{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: 20px;
}

.viewButtons button{
  border: none;
  color: #FFF;
  background-color: #1a2530;
  padding: 10px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.viewButtons button:hover{
  border: solid 2px #1a2530;
  background-color: transparent;
}

.modalView{
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.modalViewContent{
  width: 85%;
  height: 75%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  /*justify-content: flex-start;*/
  align-items: center;
  justify-content: center;
}

.modalViewHeader{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalViewHeader label{
  margin-left: 10px;
  color: #000;
  font-weight: bold;
  font-size: 20px;
}

.modalViewHeader button{
  border: none;
  background-color: red;
  color: #FFF;
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.modalViewContent table{
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 80%;
}

.modalViewContent table tr{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  background-color: #1a2530;
  
}

/*
.modalViewContent table tr{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: red;
}*/

/*.tree label:hover{
  display: inherit;
}*/


/*.tree:hover{
  background: #2d4050;
}*/

/*
.form-control{
    font-size: 20px;
}

.file-upload{
    border-radius: 5px;
    border:solid 1px gray;
    background-color: white;
    color:#25064C;
    height: 30px;
    font-size: 20px;
}

.guardar__{
    width: 140px;
    height: 40px;
    margin-top: 30px;
    border:none;
    border-radius: 5px;
    background-color: #25064C;
    color:#fff;
}

.files-hide,.add-hide{
    display: none;
}

.files-visible,.add-visible{
    display: inherit;
}

.menuIzquierdo{
    margin-top: 20px;
    width: auto;
    height: 100% - 80;
    text-align: left;
    border-right: solid 1px #ECEAEF;
    background-color: white;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
}

.resultadosArchivos{
    width: 100%;
    padding: 10px;
    height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.archivosCabecera{
    display: flex;
    flex-direction: row;
}

.archivosCabecera h2{
    margin-left: 30px;
}

.archivosCabecera button{
    background-color: transparent;
    border:none;
    margin:3px;
    cursor: pointer;
}

.archivosCabecera img{
    width: 20px;
    height: 20px;
}

.folders{
    display: flex;
    flex-wrap: wrap;
}

.folders_{
    display: flex;
    flex-direction: column;
}

.folder{
    border:none;
    background-color: transparent;
    width: 120px;
    padding: 10px;
    height: auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.folder_{
    border:none;
    background-color: transparent;
    width: 100%;
    padding: 10px;
    height: auto;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.folder:hover,.folder_:hover{
    background-color: aqua;
}

.folder img{
    width: 80px;
    height: 80px;
}

.folder_ img{
    width: 20px;
    height: 20px;
}

.folder label,.folder_ label{
    font-size: 12px;
}

.folder_ label{
    margin-left: 10px;
}


.addFile{
    cursor: pointer;
    position: fixed;
    right: 10px;
    bottom: 10px;
    font-size: 40px;
    font-weight: bold;
    color:#fff;
    background-color: #25064C;
    border:none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.listaArchivos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.archivo a{
    color:black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.archivo img{
    width: 30px;
    height: 30px;
}

.form-bitacora{
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 100px;
}

.form-perfil{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    position: fixed;
    left: 250px;
    bottom: 0;
    padding-bottom: 50px;
    -webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.75);
}

.form-group{
    display: flex;
    flex-direction: column;
}

.icono{
    width: 50px;
    height: 50px;
}

.subir{
    padding: 5px 10px;
    background: #f55d3e;
    color:#fff;
    border:0px solid #fff;
}

.subir:hover{
    color:#fff;
    background: #f7cb15;
}



.opcionMenu{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 5px;
}

.nameMenu{
    background-color: white;
    color:black;
    padding: 5px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
}

.nameMenu:hover{
    background-color: lightblue;
}

.opcionMenu:hover .menuSubOptions{
    display:initial;
}

.menuSubOptions{
    cursor: pointer;
    display: none;
    margin-left: 5px;
}

.menuSubOptions h6:hover{
    background: black;
    color:white;
}*/
