#standard-list {
    background-color: #1a2530;
    overflow-y: auto;
    height: 92vh;
}

#standard-list:hover {
    background-color: #1a2530;
    overflow-y: auto;
    height: 92vh;
}

.budget-list-row.selected {
    background-color: teal;
}

.budget-list-col {
    color: white;
    border-bottom: solid #263545 0.02px;
    padding: 2%;
    cursor: pointer;
    width: 100%;
}

.budget-list-col:hover {
    background-color: teal;
}