/*.subMenuVertical{
    background: white;
    color: black;
    width: auto;
    height: 100px;
    max-width: 300px;
}

a:hover{
    padding: 10px 30px;
    background: red;
}
*/

.sectionSearch{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #1a2530;
}

.sectionSearch article{
    display:flex;
    flex-direction: column;
    margin:20;
}

.sectionSearch label{
    color:#FFF;
    text-align: center;
}

.sectionSearch input, .sectionSearch select, .formInput{
    width: 200px;
    height: 25px;
    border-radius: 5px;
    margin: 10px;
    background: #2d4050;
    border: none;
    color: #FFF;
}


.subMenuVertical{
    background-color: rgb(245, 241, 237);
    width: auto;
    float: left;
}

.subMenuVertical ul{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: solid 1px black;
}


.subMenuVertical li{
    /*padding: 10px 30px 10px 2px;*/
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.subMenuVertical a{
	font-family: 'Lato';
	color: #3C3526;
	text-decoration: none;
    padding: 10px;;
}


.subMenuVertical li:hover{
	background-color: #E0F6F5;
}


.navSubM > li {
    float: left;
}

.navSubM li ul {
    top: 22%;
    left: 203px;
    width: auto;
    display:none;
    position: absolute;
    text-align: left;
}

.navSubM li .subMenu_{
    left: 350px;
    width: 300px;
}


.navSubM li ul li{
    background-color: white;
}

/*
.navSubM li ul>li:hover{
    background-color: #C89936;
}*/

.navSubM >ul >li:hover>ul{

}
.navSubM li:hover > ul {
    display:block;
}

.navSubM li ul li {
    position:relative;
}

.navSubM li ul li ul {
    /*right:-140px;*/
    top:0px;
}


.formularioBusqueda{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.tituloSub{
    color: white;
    padding: 10px;
    background-color: #1a2530;
    text-align: center;
}

.sectionBusqueda{
    display: flex;
    flex-wrap: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.articleBusqueda{
    padding: 10px;
    border: 0px;
    border-radius: 2px;
    margin: 3px;
}
.mov{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}


.fch, .tipoB{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.body_{
  width: 93vw;
}

.body_ table{
  padding: 20px 25px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.body_ div.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a2530;
}

.botonBusqueda{
    padding: 10px 30px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    background-color: #2d4050;
    border: none;
}

.buttonsPreviusNext{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /*background-color: teal;*/
}

.buttonsPreviusNext label{
  color: #FFF;
}

.buttonsPreviusNext button{
    cursor: pointer;
    border: none;
    background-color: beige;
    color:#000;
    padding: 10px;
    border-radius: 50px;
}

legend{
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Lato';
}
.criterios{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items:flex-start;
    margin: 5px;
}

.criterios1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    margin: 5px;
}

.criterios label,.criterios1 label{
    display: flex;
    width: auto;
    flex-direction: row;
    align-content: center;
    align-items: center;
    font-family: 'Lato';
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    padding: 1px;
}

.criterios1 select, .DatePicker{
    width: 100px;
    height: 30px;
    font-size: 16px;
    font-family: 'Lato';
    margin: 0px 10px;
    width: 150px;
}

.tituloClasificacion{
    margin-left: -20px;
}

input[type="checkbox"]{
    margin: 0px 5px 0px 0px;
}

.organizacion{
    margin: 0px 5px;
}

label{
    margin:3px 0px;
}

.checkbox{
    margin: 0px 5px;
    background: #3059C7;
    color:white;
    padding: 3px 4px;
    border-radius: 5px;
    border-bottom: solid 4px #402DCA;
}

.checkbox input[type="checkbox"]{
    content: "f111";
}



.criterios label,.criterios1 label, .criterios input,.criterios select option{
    font-size: 16px;
}

.menuFiltrosHorizontales{
    display: flex;
    flex-direction: row;
    height: auto;
}


.contenidoMasMenu{
    display: flex;
    flex-direction: column;
}

.menuFiltrosHorizontales section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.menuFiltrosVerticales{
    display: flex;
    flex-direction: column;
    min-width: 280px;
    border:0px;
    padding: 3px;
}

.fcha{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
    background-color: white;
    margin: 0px 10px;
}

.menuFiltrosHorizontales article{
    display: flex;
    flex-direction: row;
}

.date{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.fcha label{
    margin: 5px 5px;
    font-size: 12px;
}




/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: green;
    -webkit-transition: .2s;
    transition: .2s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(136px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 17px;
  }

.slider.round:before {
    border-radius: 50%;
}

.inline{
    display: flex;
    flex-direction: row;
}

 .tipo{
    display: flex;
    /*flex: 1 1 auto;*/
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.tipo div{
    padding: 10px;
}

.tipo >label{
    margin: 3px 0px;
    flex:1 1 300px;
}

.demo{
    margin: -10px 0px;
}

.demo2{
    margin: 0px 15px 10px 0px;
}

.demo input[type="checkbox"],.demo2 input[type="checkbox"] {
    display: none;
    margin: 0;
}
.demo input[type="checkbox"] + label span,.demo2 input[type="checkbox"] + label span {
	display: inline-block;
	width: 18px;
	height: 18px;
	margin: -1px 4px 0 0;
	vertical-align: middle;
	background: url(../../../../img/checkbox-uncheck.png);
	background-size: cover;
	cursor: pointer;
}


.demo input[type="checkbox"]:checked + label span,.demo2 input[type="checkbox"]:checked + label span {
	background: url(../../../../img/checkbox-check.png);
	background-size: cover;
}

.buttonOpenInventario{
    border: none;
    background-color: #2d4050;
    color: #FFF;
    position: absolute;
    margin: 2px;
    padding: 5px 10px;
    right: 0;
    font-size: 15px;
    cursor: pointer;
}

.modalInventory{
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 100px);
}

.modalContentInventory{
    background-color: #FFF;
    width: 80%;
    padding: 20px 30px;
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalContentInventory h2{
    color: #1a2530;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
}

.modalContentInventory label{
    color: #1a2530;
}

.contentFileModal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.buttonFileModal{
    display: none;
}

label.buttonFileModal2{
    background-color: tomato;
    text-align: center;
    width: 300px !important;
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: bold;
    
}

.buttonFileModal2 span{
    color: white;
    font-size: 13px;
}



.buttonGetInformation{
    background-color: teal;
    border: none;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sheets{
    display: flex;
    flex-direction: column;
}

.sheet {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    border: solid 1px #2d4050;
    padding: 5px;
    margin: 1px;
    border-radius: 3px;
    width: 400px;
}

.sheet label{
    margin-left: 10px;
}

.designTableExcel{
    max-height: 500px;
    overflow-x: scroll;
    overflow-y: scroll;
    overflow-wrap: break-word;
}

.designTableExcel table{
    position: relative;
}

.designTableExcel table thead tr th{
    position: sticky;
    top: 0;
}

.designTableExcel th{
    min-width: 250px;
    padding: 20px;
}


.moveTable-left, .moveTable-up, .moveTable-down, .moveTable-right{
    background-color: white;
    color: #1a2530;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 1px #1a2530;
    font-size: 30px;
    cursor: pointer;
}

.moveTable-left{
    left: 0;
    top: 300px;
    position: sticky;
}

.moveTable-right{
    position: sticky;
    top: 300px;
    left: 100%;
}

.moveTable-down{
    position: sticky;
    top: calc(100% + 40px);
    left: 50%;
}

.moveTable-up{
    position: sticky;
    left: 50%;
    top: 40px;
}

.errorHeaderExcel{
    color: red !important;
    font-size: 25px;
}

.saveOnlineExcel{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.saveOnlineExcel button{
    margin: 20px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #1a2530;
    padding: 10px;
    border-radius: 3px;
    font-size: 20px;
}

.closeButtonModal{
    color: white;
    background-color: red;
    border: none;
    padding: 10px 15px;
    font-size: 20px;
    float: right;
    cursor: pointer;
}

.toggles{
    background-color: #1a2530;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.toggles article{
    margin: 5px;
}

.toggles article input[type="button"]{
    cursor: pointer;
    background-color: #2d4050;
    border: none;
    color: white;
    padding: 5px;
    border-radius: 4px;
}

.toggles article input[type="button"].active{
    color: #2d4050;
    background-color: white;
}

.formInventoryOne{
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
}

.formInventoryOne article{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formInventoryOne label{
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;
    width: 100%;
}

.formInventoryOne input, .formInventoryOne textarea{
    max-width: 100%;
    min-width: 100%;
    border: solid 1px #1a2530;
    padding: 5px;
    border-radius: 3px;
}

.formInventoryOne button{
    background-color: #1a2530;
    border: none;
    color: white;
    margin: 10px;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    width: 300px;
}

.imageInventory{
    width: 100%;
    height: auto;
}