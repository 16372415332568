.body__{
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.registro ul, .propiedadesAbono ul{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.registro li, .propiedadesAbono li{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.registro label,.propiedadesAbono label{
    font-size: 14px;
    color: black;
}

.registro select, .propiedadesAbono input,.propiedadesAbono select{
    height: 30px;
    border-radius: 3px;
    color: #605E5D;
    border:solid 1px #E1DEDB;
    font-family: 'Lato';
    margin-top: 3px;
    margin-bottom: 10px;
    width: 300px;
}

.guardar{
    background-color: green;
    padding: 10px 25px;
    border-radius: 5px;
    border-bottom: solid 2px #21AF0D;
}