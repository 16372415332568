/*.body-top {
	background-color: #324F6D;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.subMenuHorizontal{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	background-color: #F37506;
	justify-content: flex-start;
	align-items: center;
}

.subMenuHorizontal ul{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.subMenuHorizontal li{
	padding: 10px 30px;
}

.subMenuHorizontal li ul{
    display:none;
	position: absolute;
	text-align: left;
	top:129px;
}

.subMenuHorizontal li:hover > ul {
    display:block;
}

.subMenuHorizontal a{
	font-family: 'Lato';
	color: white;
	text-decoration: none;
	padding: 10px;
}


.subMenuHorizontal a:hover{
	color: #3C3526;
}

.contenidoSubMenu{
	width: 100%;
	background-color: white;
}

.buscar{
	border: solid 1px #3C3526;
	font-size: 15px;
}

.subMenuVerticalP{
	display: flex;
	flex-direction: column;
	background-color: #D2D1D7;
}

.subMenuVerticalP li a{
	color:black;
}

.subMenuVerticalP li{
	left: 0;
	margin-left: 0px;
}

.subMenuVerticalP li:hover {
	background-color: white;
}

.subMenuVerticalP li:hover > ul {
	display:block;
	top:0;
	left: 100%;
	width: 400px;
	background-color:#D2D1D7;
}

.subMenuVerticalP li:hover > .subMenu_ {
	display:block;
	top:0;
	left: 100%;
	width: 150%;
	background-color:#D2D1D7;
}
a{
	font-size: 15px;
}


*/
/**************************/

.contentPro {
	margin: 10px 0px 0px 10px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #1a2530;
}

.contentStage {
	padding: 10px;
	border-bottom: solid #263545 0.02px;
	cursor: pointer;
	background-color: transparent;
	align-items: center;
	text-decoration: none;
	min-height: 75px;
	max-width: 300px;
	min-width: 250px;
}

.contentStage:hover {
	text-decoration: none;
	background-color: white;
}

.contentStage:hover .nombreP{
	color: #1a2530;
}
.contentStage:hover .nombreS{
	color: #1a2530;
}

.contentP {
	display: flex;
	flex-direction: row;
	padding: 10px;
	border-bottom: solid #263545 0.02px;
	cursor: pointer;
	background-color: transparent;
	align-items: center;
	text-decoration: none;
	min-height: 75px;
	max-width: 300px;
	min-width: 250px;
}

.contentP:hover {
	text-decoration: none;
	background-color: white;
}

.contentP:hover .nombreP {
	color: #1a2530;
}

.contentP:hover .nombreS {
	color: #1a2530;
}
.code {
	font-weight: bold;
}

/*.estandaresMantenimiento{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.estandaresMantenimiento li:hover{
	background-color: #CC0550;
}
*/
li {
	list-style: none;
}

/*.estandaresMantenimiento li{
	/*border: solid 1px #25064C;*
	background-color: white;
	padding: 10px 15px;
	margin: 5px 10px;
	width: 25%;
	text-align: left;

}

.estandaresMantenimiento li a{
	color: #25064C;
	text-align: left;
}*/

.estandaresMantenimiento {
	margin: 10px 10px 0px 0px;
	max-width: 300px;
	background-color: #1a2530;
}

.estandaresMantenimiento li {
	min-height: 55px;
	display: flex;
	flex: 1;
	cursor: pointer;
	padding: 10px;
	border-bottom: solid 0.5px #263545;
}

.estandaresMantenimiento li a {
	text-decoration: none;
	color: #FFF;
}

.imagenP {
	width: 50px;
	height: 50px;
	cursor: pointer;
	border-radius: 25px;
	background-color: white;
	padding: 3px;
}

.nombreP {
	cursor: pointer;
	color: #FFF;
	font-size: 15px;
	margin-left: 10px;
}

.nombreS {
	cursor: pointer;
	color: #F0E68C;
	font-size: 11px;
	margin-left: 10px;
	margin-top: 5%;
}

.contenidoOpciones {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

.tituloSubOpciones {
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 30px;
	color: #FFF;
	text-align: center;
	background-color: #1a2530;
}

.subTituloSubOpciones {
	font-size: 20px;
}

.operacionOpciones {
	display: flex;
	flex-direction: column;
	background-color: #1a2530;
	max-width: 300px;
	min-width: 250px;
	margin: 10px 10px 0px 0px;
}

.operacionOpcion {
	padding: 10px;
	cursor: pointer;
	border-bottom: solid 0.5px #263545;
}

.operacionOpcionLink {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	min-height: 50px;
}

.operacionOpcionLink:hover {
	text-decoration: none;
	color: white;
}

.operacionOpcion img {
	width: 50px;
	height: 50px;
	color: #25064C;
	-webkit-filter: HSL(120°, 100%, 50%);
	filter: HSL(120°, 100%, 50%);
	border-radius: 50%;
	margin-right: 5px;
}

.con_ {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.operacionOpcion a {
	color: black;
	text-align: left;
	text-decoration: none;
	color: white;
	align-items: center;
	font-size: 15px;

}

.estandares {
	display: flex;
	flex-direction: column;
	/*background-color: #2d4050;*/
	/*border:solid 1px #b9b9b9;*/
}

.estandares h1 {
	color: white;
	font-size: 25px;
	text-align: center;
}

.div {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.estandaresGenerales {
	margin: 10px 10px 0px 0px;
	max-width: 300px;
	background-color: #1a2530;
}

.estandaresSecundarios {
	max-width: 300px;
	background-color: #1a2530;
	margin: 10px 10px 0px 0px;
}


.estandaresGenerales ul,
.estandaresSecundarios section {
	/*display: flex;
	flex-direction: column;*/
}

.estandaresGenerales ul li {
	display: flex;
	flex: 1;
	cursor: pointer;
	padding: 10px;
	border-bottom: solid 0.5px #263545;
	min-height: 71px;
}

.estandaresGenerales ul li:hover {
	/*background-color: #1a2530;*/
}

/*.estandaresGenerales ul li:hover > a{
	color: white;
}*/

.estandaresSecundarios section li:hover {
	/*background-color: #2d4050;*/
}

.estandaresGenerales ul li a {
	color: #FFF;
}

.estandaresSecundarios section li a {
	color: #FFF;
	display: flex;
	flex: 1;
	cursor: pointer;
	padding: 10px;
	text-align: left;
	min-height: 71px;
	border-bottom: solid 0.5px #263545;
}

.estandaresGenerales ul li a,
.estandaresSecundarios section li a {
	display: flex;
	text-decoration: none;
	text-align: left;
}

.estandaresGenerales .active {
	background: #2d4050;
}

.estandaresGenerales .active a {
	color: #F5F2F9;
}