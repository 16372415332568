#PaymentForm .card:hover {
    cursor: pointer !important;
    background-color: #808080;
    color: white !important;
    transform: scale(1.05);
}

#PaymentForm .card:hover>.card-body .text-muted{
    color: white !important;
}

#PaymentForm .card {
    padding: 5% !important;
    transition: transform .2s; /* Animation */
}

#PaymentForm .card-body {
    margin: 0 !important;
    padding: 0 !important;
}

#PaymentForm .card-title{
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

#PaymentForm .card-body p{
    margin-bottom: 0 !important;
}

#PaymentForm .row .col center#addCard{
    transition: transform .2s; /* Animation */
}

#PaymentForm .row .col center#addCard:hover{
    cursor: pointer !important;
    transform: scale(1.5);
}

#PaymentForm h4{
    color: black;
}