.selected {
    background-color: teal;
}

.selected td {
    color: white;
}

.balance:hover {
    background-color: #e3e7e8;
}

.balance:hover>td {
    color: black;

}

.scrollvertical {
    height: 70vh !important;
    overflow-y: auto !important;

}

.scrollvertical thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}


.scrollvertical tbody.tbody tr {
    cursor: pointer;
}