.custom-qr {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.buttom-regenerate-qr {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}