/*
#1a2530;
#2d4050;
*/
.content_ds {
    width: 100%;
    height: auto;
    background-color: #2d4050;
    display: flex;
    flex-wrap: wrap;
}

.content_ds li {
    width: 270px;
    border: solid 3px #2d4050;
    margin: 5px 5px;
}

.content_ds_header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #1a2530;
    color: white;
    width: 100%;
    padding: 5px;
}

.content_ds_body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    color: #1a2530;
    width: 100%;
    padding: 5px;
}

.content_ds_body img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: 250px;
}

/*Creado*/
.status1 {
    background-color: #BA3434 !important;
}

/*Levantado*/
.status2 {
    background-color: #34BA65 !important;
}

/*Atendiendo*/
.status3 {
    background-color: #0C320F !important;
}

/*Cerrado*/
.status4 {
    background-color: #3454BA !important;
}

/*Cerrado con retraso*/
.status5 {
    background-color: #EC6E12 !important;
}

/*No hecho*/
.status6 {
    background-color: #151515 !important;
}

/*Completado*/
.status7 {
    background-color: #092068;
}


.incident_modal {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    opacity:1;
    color:white;*/
}

.modal_contenido {
    background-color: #2d4050;
    color: #FFF;
    width: 98%;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
}

.modal_header {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.modal_header h1 {
    width: 95%;
    font-size: 20px;
}

.modal_header button {
    border-top-right-radius: 5px;
    border: none;
    background-color: red;
    color: white;
    padding: 5px 10px;
    width: 5%;
}

.modal_body {
    /*padding: 10px 20px;*/
    height: 90vh;
    overflow: auto;
    display: flex;
    flex-direction: row;
}

.previusTable {
    text-align: center;
    margin-top: 50%;
}

.modal_body__ {
    flex-direction: column;
    background-color: #1a2530;
}

.modal_body_ {
    flex-direction: column;
}

.tableTools {
    margin: 40px 1px 1px !important;
    padding: 3px !important;
    width: 100%;
}

.tableTools tr:hover {
    background-color: rgb(23, 160, 160) !important;
}

.tableTools tr td,
.tableTools tr td input {
    font-size: 12px;
    padding: 3px 2px;
    color: #1a2530 !important;
}


.footerMaterials {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0px;
}

.saveMaterials {
    border: none;
    cursor: pointer;
    background-color: #1a2530;
    border-radius: 10px;
    padding: 5px 10px;
    color: #FFF;
}

.tableTools tr td input[type="number"] {
    width: 100px;
}

.tableTools tr td.left,
.left {
    text-align: left !important;
}


.tableTools tr td.center,
.center {
    text-align: center !important;
}

.tableTools tr td.right,
.right {
    text-align: right !important;
}

.up {
    display: flex;
    flex-direction: column;
    border: none;
}

.controlsAudioTime {
    display: flex !important;
    flex-direction: row !important;
}

.controlsAudioIndicatorTime {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 10px !important;
    border-radius: 10px;
    margin-top: -3px;
}

.apply_deductive {
    display: flex;
    justify-content: center;
}

.apply_deductive div {
    color: #FFF;
    background-color: #1a2530;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.image {
    width: 200px;
    height: 250px;
    border: solid 2px #1a2530;
}

.mediaPhotos {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #1a2530;
}

.scroll {
    margin: 0px !important;
    padding: 0px !important;
    border: solid 2px #1a2530;
    height: 250px;
    width: 100%;
    overflow: auto;
}

.scroll article {
    padding: 5px;
    border-bottom: solid 0.5px #c0c2a7;
    background-color: #e3e7e8;
}

.scroll article li {
    color: #1a2530;
}

.formulas {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buttonApply {
    border: none;
    cursor: pointer;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
    padding: 0px 10px;
    border-radius: 3px;
}

.metodos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.itemMetodo {
    margin: 5px;
    padding: 10px;
    background-color: #1a2530;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.itemMetodo div {
    display: flex;
    flex-direction: row;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #F87DA9;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.inputFormula {
    text-align: center;
    font-size: 18px;
    border: none;
    padding: 10px;
    margin-top: 5px;
}

.inputFormulaD {
    text-align: center;
    font-size: 18px;
    border: none;
    padding: 10px;
    margin-top: 5px;
    color: #FFF;
    background-color: #1a2530;
}

.modal_users {
    display: flex;
    flex-wrap: wrap;
    background-color: #1a2530;
    color: white;
    padding: 10px 20px;
}

.modal_informationDeductive {
    display: flex;
    flex-direction: column;
    background-color: #1a2530;
    color: white;
    padding: 10px 20px;
}

.modal_users article {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.modal_details {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
}

.modal_details article {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.modal_media {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100% !important;
}

.modal_media_header_moment {
    width: 100%;
}

.modal_media_header_moment button {
    width: 33.33%;
    font-size: 15px;
    border: none;
    color: #FFF;
    padding: 10px 0px;
    cursor: pointer;
}

.content_media {
    padding: 10px;
    width: 100%;
}

.contentMedia {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.picturesStyle {
    width: 100px;
    height: 100px;
}

.desactive_ {
    background-color: #2d4050;
}

.tableMedia {
    width: 100% !important;
}

.active_ {
    background-color: #1a2530;
}

.comments {
    margin: 5px;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
}

.comments div {
    color: #1a2530;
}

/*.modal_media article{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}*/

.media_comment {
    width: 80%;
    resize: none;
}

.media_audio {
    width: 40px;
    height: 40px;
}

.media_imagen,
.media_video {
    width: 80%;
    height: 200;
}


.closeModal {
    cursor: pointer;
    border-radius: 20px;
    ;
}

.containerWorkOrder {
    display: flex;
    flex-direction: row;
}

.listWorkOrder {
    width: 30%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.listWorkOrder article {
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    margin: 0px 5px;
}

.listWorkOrder label {
    color: #1a2530;
    font-size: 13px;
    cursor: pointer;
}

.listWorkOrder h2 {
    font-size: 17px;
    color: #1a2530;
    cursor: pointer;
}

.active_card {
    background-color: #EAEBEB;
}

.suppliesWorkOrder {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.headerSuppliesWorkOrder h2 {
    color: #1a2530;
    font-size: 20px;
}

.centerDiv {
    padding: 3px;
    display: flex;
    justify-content: center;
}

.workOrderTable tr:hover {
    background-color: #2d4050 !important;
}

.workOrderTable tr:hover td {
    color: #fff;
}

.workOrderTable tr:hover td input {
    color: #000;
}
.right-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.viewPDF_ {
    width: 100% !important;
    height: 500px !important;
}

.viewPDF_ .view {
    width: 100% !important;
    height: 100% !important;
}

.PDFDownloadLink,
.XLSXDownloadLink {
    padding: 0.25rem 1.5rem;
    color: black !important;
    text-decoration: none !important;
}

.XLSXDownloadLink {
    border: none;
    background-color: transparent !important;
}