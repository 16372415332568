.body {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


.body .h1{
	font-size: 250%;
  color: white;
}

.body .info{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  color: white;
}

.body p{
	margin: 0px 10px 10px 0px;
}

.li{
	margin: 7px 0px 7px 0px;
  list-style: none;
}

.logo_cliente{
	width: 400px;
	height: 150px;
}

h1, h2{
  color: white;
}

.appMobile{
	margin-right: 10px;
	width: 150px;
	height: 320px;
	background-color: black;
	border:solid 1px black;
	padding: 20px 8px;
	border-radius: 20px;
}

.imageAppMobile{
	width: 100%;
	height: 100%;
}
