.twoColumns{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}


.twoColumns .info{
    display: flex;
    flex-direction: column;
    padding: 30px;
    justify-content: center;
    align-items: center;
    background-color: #1a2530;
}

.twoColumns .info h1{
    text-align: center;
}

.twoColumns .info p ul li.li{
    color: white;
}

.twoColumns .info, .twoColumns .form{
    width: 50%;
}

.error{
    font-weight: bold;
    color: #F00;
}

.form{
    background-color: transparent;
    background-image: url(../../img/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.black{
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login{
    background-color: white;
    width: 70%;
    padding: 10px;
    border-radius: 10px;
    border: solid 5px #F4F6F6;
    display: flex;
    flex-direction: column;
}

.login h3{
    color: #1a2530;
    text-align: center;
    font-weight: bold;
}

.login img{
    margin-left: calc(50% - 55px);
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    padding: 5px;
}

.login video{
    margin-left: calc(50% - 55px);
    background-color: white;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    padding: 5px;
}

.login input{
    width: 100%;
    /*padding: 5px 10px;
    margin: 5px;
    width: 250px;
    border-radius: 10px;
    border-width: 0px;*/
}

.login .loginB{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loadingCCC{
    margin-left: 10px;
    width: 20px;
    height: 20px;
    background-color: none;
    border: solid 3px #CCC;
    border-radius: 50%;
    border-right: solid 3px transparent;
    animation: 1s infinite;
    animation-name: girar;
}

@keyframes girar{
    from {
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

@media(max-width: 500px){
    .twoColumns{
        flex-direction: column;   
    }
    .twoColumns .info, .twoColumns .form{
        width: 100%;
    }
}

/*.opacity{
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255, 0.8);
    backface-visibility: visible;
}*/
