.cajaOld {
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  position: sticky;
  top: 0;
  background-color: #2d4050;
  z-index: 100;
  height: 5vh;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5 0;
}

.caja {
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  position: sticky;
  top: 0;
  background-color: #2d4050;
  z-index: 100;
  height: 5vh;
  padding: 0 5 0;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
}

.bc-text {
  flex: 6;
}

.select-stages-div {
  flex: 1;
}

.select-stages {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: none;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  /* width: 100%; */
  cursor: pointer;
  color: #fff;
  /* Color del texto en modo oscuro */
  background-color: #1a2530;
  /* Color de fondo en modo oscuro */
}

/* Estilo al pasar el ratón sobre el select */
.select-stages:hover {
  border: 1px solid #fff;
}

/* Estilo cuando el select está enfocado */
.select-stages:focus {
  outline: none;
  border: 1px solid #fff;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}

.caja .bc-text .bc-label {
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto
}
