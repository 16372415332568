* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/lato/Lato-Regular.ttf");
}

body {
  font-family: 'Lato';
}

.body_contenido {
  display: flex;
  flex-direction: row;
}

.menu {
  overflow-y: auto;
  width: 100px;
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  background-color: #1a2530;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
}

.logout {
  color: #FFF;
  width: 30px;
  height: 30px;
}

.boton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  color: #FFF;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.body_contenido .menu .boton small {
  text-align: center;
}

.boton a {
  text-align: center;
  text-decoration: none;
  color: #FFF;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.boton:hover {
  text-decoration: none;
  color: white;
}

.desactive>.icon_name {
  background-color: #2d4050;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.color_icono {
  color: #1a2530;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.active>.icon_name {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.contenido {
  width: 100%;
  height: 96rem;
  background-color: #2d4050;
  margin-left: 100px;
}

.profile:hover>.profile_info {
  display: initial;
}

.profile_info {
  margin-top: 100px;
  display: none;
  border-radius: 5px;
  padding: 5px;
  text-align: left;
  background-color: #34495e;
  min-width: 250px;
  position: fixed;
  left: 70px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
}