.Resultados {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #F9F9F9;
    color: #544747;
    padding: 20px 0px;
}

.gridResultados {
    margin: 0px 0px;
    border-spacing: 20px 10px;
    border: solid 1px #544747;
}

.gridResultados tr {
    border: solid 4px #544747;
}


.gridResultados tr td {
    padding: 0px 10px;
}

.datosBusqueda {
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.resultadosView ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1 1;
}

.resultadosView ul li {
    flex: 1;
    width: 250px;
    margin: 0px 10px;
}

.resultadosView ul li a {
    width: 100%;
    height: 100%;
    padding: 5px 30px;
    color: white;
    border-radius: 20px;
    background: #0378D1;

}

.tarjeta {
    border: solid 1px #B0BDC6;
    width: 200px;
    height: 100px;
    background: white;
}

.tituloTarjeta {
    background-color: #6F2DFB;
    width: 100%;
    height: 20%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

table {
    /*width: 100%;*/
    align-items: center;
    padding: 10px;
    border-spacing: 0px;
    font-size: 15px;
    /*margin: 25px;*/
}

tr {
    background: #e3e7e8;
}

div table tr th {
    background-color: #1a2530;
    padding: 10px 0px;
    color: white;
    text-align: center !important;
    position: sticky;
    top: 35px;
}

.mediaInicidentes {
    width: 50% !important;
    height: auto !important;
}

.cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    margin: 5px;
    text-align: left;
    background-color: #FDFDF9;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
    min-width: 400px !important;
}

.card div {
    padding: 5px 10px;
}

.card-header {
    background-color: #1a2530 !important;
    color: white;
}

.card-info-hidden {
    display: none;
}

.card:hover>.card-info-hidden {
    display: initial;
    position: fixed;
    background-color: #FDFDF9;
    bottom: 0px;
    border: 0.1px solid #D8D8D8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 750px;
    right: 15px;
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
}

th.dos {
    background-color: #341081;
    padding: 10px 0px;
    color: white;
}

/* tr:hover {
    background-color: teal;
} */

tr:hover>td {
    background-color: transparent;
    color: rgb(12, 11, 11);
}

.queryConsulta {
    background-color: #1a2530;
}

.queryConsulta td {
    background-color: #1a2530;
}

.queryConsulta:hover td {
    background-color: #1a2530;
}

/*.tbody:hover{
    background-color: #ADACB1;
    color: white;
    border-bottom: solid 3px #0378D1;
}*/

/* td {
    padding: 5px 0px;
    text-align: center;
    border-bottom: solid 1px #CCC;
    color: #1a2530;
} */


.izquierda {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    background-color: green;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin: 1px;
}

.ingresoDerecho {
    text-align: right;
    margin-right: -5px;
}


.thead {
    background-color: #2F255B;
    color: white;
}

.thead th {
    padding: 15px 20px;
}

.tbody {
    background-color: white;
    color: #ADACB1;
    padding: 5px 0px;
}

.tituloTabla {
    font-size: 17px;
}

.divTablas {
    display: flex;
    flex-wrap: wrap;
}

.download {
    border: none;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-left: 100px;
    flex-direction: column;
}


.download img {
    width: 75px;
    height: 75px;
}

.download small {
    font-size: 13px;
    font-weight: bold;
    color: white;
}

.formatDefault {
    background-color: #1a2530;
    text-align: center;
    padding: 5px;
}

.formatDefault label {
    color: white;
    width: 100%;
}

.cardsInventories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cardInventory {
    background-color: #1a2530;
    padding: 5px 10px;
    margin: 5px;
    width: 250px;
}

.cardInventory label {
    color: white;
}

.cardInventoryActions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0px;
}

.cardInventoryActions button {
    background-color: #2d4050;
    border: none;
    color: #FFF;
    padding: 5px;
    margin: 2px;
    border-radius: 3px;
    cursor: pointer;
}

.cardInventoryActions button:hover {
    background-color: #FFF;
    color: #2d4050;
}

.floatAdd {
    color: white;
    background-color: #1a2530;
    border-radius: 50%;
    /*width: 50px;
    height: 50px;*/
    padding: 15px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    position: fixed;
}

.accordion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-headerBoot {
    background-color: transparent !important;
}

.imageCard {
    width: 100%;
    height: auto;
}

.pagination {
    background-color: #1a2530;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 3px 0px;
}

li.page-item {
    background-color: #2d4050 !important;
}

.page-link {
    color: white !important;
    background-color: #1a2530 !important;
}

.active .page-link {
    background-color: white !important;
    color: #2d4050 !important;
    border-color: transparent !important;
}

.disabled .page-link {
    color: white !important;
    background-color: #2d4050 !important;
}

.dataPage {
    background-color: white;
    padding: 10px 0px 0px 20px;
}