table#table_logs th#id {
    width: 3%;
    overflow: auto;
}

table#table_logs th#user {
    width: 20%;
    overflow: auto;
}

table#table_logs th#package {
    width: 10%;
    overflow: auto;
}

table#table_logs th#date {
    width: 20%;
    overflow: auto;
}

table#table_logs th#ipv4 {
    width: 10%;
    overflow: auto;
}

table#table_logs th#browser {
    width: 27%;
    overflow: auto;
}

table#table_logs th#app {
    width: 10%;
    overflow: auto;
}