.carousel-caption p {
  color: "#FFF";
  font-weight: "bolder";
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-caption:hover {
  color: "#FFF";
  font-weight: "bolder";
  background-color: rgba(0, 0, 0, 0);
  display: none;
}
.carousel-caption p:hover {
  color: "#FFF";
  font-weight: "bolder";
  background-color: rgba(0, 0, 0, 0);
  display: none;
}

.thumbnail-container {
  position: relative;
}

.delete-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
