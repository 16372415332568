.body___{
  display: flex;
  flex-direction: column;
}

.body___ table{
  width: 98.4%;
  font-size: 13px;
  background-color: white;
}

.optionInfo select{
  width: 300px;
  height: 50;
  font-size: 20px;
  margin: 5px;
  border-radius: 5px;
}

.inputsInfoMap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.inputCreated{
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.inputCreated label{
  font-size: 15px;
  color: white;
}

.loading{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000000;
}

.loading label{
  color: #fff;
  font-weight: bold;
  font-size: 30px;
}

.buttonCreated{
  cursor: pointer;
  background-color: #1a2530;
  color: white;
  border-radius: 10px;
  padding: 10px;
  border: none;
}

.viewInfo{
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 500px;
  background-color: #fff;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 5px;
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  right: 10px;
}

.viewInfo section{
  display: flex;
  justify-content: flex-end;
  
}

.viewInfo section button{
  cursor: pointer;
  border: none;
  background-color: red;
  padding: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}

.info{
  display: flex;
  flex-direction: row;
}

.inputCreated select{
  font-size: 18px;
}

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
}

.marker:hover{
  z-index: 1;
}

.tiptext {
  visibility: hidden;
  color: rgb(37, 35, 35);
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  z-index:20;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.9);
  position:fixed;
  top:-300%;
}


.bounce:hover + .tiptext {
  visibility: visible;
}

.tipTitulo{
  font-size: 13.5px;
  padding: 6px 0px;
  color:black;
  font-weight: bold;
}

.tipCuerpo{
    font-size: 12px;
    padding: 6px 0px;
}



.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #FFDC00;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
  .pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
  }
  
  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
  
  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 4px 7px #FFDC00;
    animation-delay: 1.1s;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
  
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
  
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
  
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }