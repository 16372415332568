.assets{
    margin: 10px;
    width: 100%;
}

.assets_tap{
    display: flex;
    flex-direction: row;
}

.button_desactive{
    border: none;
    background-color: #2d4050;
    color: #FFF;
    padding: 10px;
    cursor: pointer;
}

.button_desactive:hover, .button_active{
    background-color: #1a2530;
    border: none;
    color: #FFF;
    padding: 10px;
    cursor: pointer;
}

.tableAssets{
    width: 100%;
}

.calendar{
    background-color: #FFF;
    /*height: 100%;*/
}

.rbc-day-bg{
    height: 100%;
    background-color: #FFF;
}

.rbc-day-bg + .rbc-today{
    background-color: #008FF9;
}

div.rbc-event{
    background-color: transparent;
}

div.rbc-event-content span{
    padding: 5px;
    border-radius: 15px;
}

.modalBackground{
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 20px;
    z-index: 2000;
}

.modalContent{
    background-color: #FFF;
    width: 60%;
    height: 80%;
    overflow-y: auto;
    /*margin-top: -10%;*/
}

.modalHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
}

.modalHeader h1{
    color: #000;
}

.modalHeader button{
    cursor: pointer;
    width: 30px;
    font-size: 20px;
    color: #FFF;
    border: none;
    background-color: crimson;
}

.modalInfo{
    display: flex;
    flex-direction: row;
}

.modalData{
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.modalData h3{
    margin-top: 15px;
}

.evidences{
    width: 60%;
    display: flex;
    flex-direction: column;
}

.evidences section{
    display: flex;
    flex-wrap: wrap;
}

.photos{
    width: 120px;
    height: 200px;
    border-radius: 10px;
    margin-right: 2px;
    border: solid 1px #000;
}
/*
.asset{
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    cursor: pointer;
}

.asset .statusLabel{
    background-color: #1a2530;
    color: #FFF;
    padding: 5px 10px;
    cursor: pointer;
}

.asset label{
    color: #1a2530;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.modalAsset{
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 20px;
}

.contentModalAsset{
    background-color: #FFF;
    max-width: 75%;
    min-width: 50%;
}

.contentModalAsset header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 30px;
}

.contentModalAsset label{
    font-weight: bold;
}

.contentModalAsset header label{
    font-size: 23px;
}

.asset-close{
    color: #FFF;
    cursor: pointer;
    background-color: #F91700;
    width: 30px;
    height: 30px;
}

.informationModalAsset{
    padding: 30px;
}*/

.modalBackground{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
}

.modalActivity{
    background-color: #FFF;
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;
    height: 95vh;
    overflow-y: auto;
    
}

.section_columns{
    height: 85vh;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    width: 100%;
    margin-top: 5px;
}

.modalActivity label{
    color: #1a2530 !important;
    font-size: 15px !important;
    font-weight: normal !important;
}

.bottom{
    width: 100%;
}

.close_button{
    font-size: 20px;
    width: 30px;
    border: none;
    background-color: red;
    color: white;
    cursor: pointer;
    float: right;
}

.td_clickeable{
    cursor: pointer;
}

.td_clickeable:hover{
    background-color: #1a2530;
    color: white;
}

.classInputDateTime{
    border: solid 1px #1a2530;
    border-radius: 5px;
    width: 95%;
    background-color: #1a2530;
    color: #FFF;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.contenedorimagenes{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.imageMan{
    width: 220px;
    height: 220px;
    margin: 3px;
    border: solid 1px black;
    border-radius: 10px;
}