html, body {
    height: 100%;
    /* overflow: hidden; */
}

#custom-gantt {
    width: 100%;
    height: 66vh;
    position: sticky;
}

.gantt_task_line.gantt_dependent_task {
    background-color: #65c16f;
    border: 1px solid #3c9445;

}

.gantt_task_line.gantt_dependent_task .gantt_task_progress {
    background-color: #46ad51;
}

.status_line {
    background-color: #0ca30a;
}

.gantt_row_project {
    font-weight: bold;
}

